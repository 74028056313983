import React from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"

// import material ui
import Typography from "@material-ui/core/Typography"

// import app components
import Edges from "components/edges"
import ScrollToTop from "components/scrollToTop"
import FooterMenu from "components/menu/FooterMenu"
import Socials from "./Socials"
import Consent from "./Consent"
import LogoIcon from "../../../static/RealCedar_simple-white.svg"

import * as theme from "theme"
import Form from "../form"

const Footer = () => {
  const [ref, inView] = useInView()

  return (
    <>
      <StyledFooter ref={ref}>
        <ScrollToTop fixed={!inView} aria-hidden="true" />

        <Edges size="lg">
          <Row>
            <Container>
              <LogoIcon />
            </Container>

            <MenuContainer>
              <FooterMenu />
            </MenuContainer>

            <FormSocialContainer>
              <FormContainer>
                <Form formId={56} hideTitle={true} hideTitle={true} footerForm={true} />
              </FormContainer>
              <SocialsContainer>
                <Socials />
              </SocialsContainer>
            </FormSocialContainer>
          </Row>
        </Edges>
      </StyledFooter>

      <CredentialsContainer>
        <Edges size="lg">
          <Row>
            <Typography color="inherit">&copy; {new Date().getFullYear()} All rights reserved</Typography>

            <ExternalLink href="https://jambaree.com/" target="_blank" rel="noopener">
              <Typography color="inherit">Gatsby Website Development by Jambaree</Typography>
            </ExternalLink>
          </Row>
        </Edges>
      </CredentialsContainer>

      {process.env.GATSBY_ACTIVE_LANGUAGE === "ZH" && (
        <ICPNumber>
          <Typography children="沪ICP备20013338号" color="inherit" />
        </ICPNumber>
      )}

      <Consent />
    </>
  )
}

export default Footer

const StyledFooter = styled.footer`
  color: #fff;
  background: #2c312c;
  position: relative;
  padding: 30px 0;

  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    padding: 60px 0;
  }
`
const ExternalLink = styled.a`
  text-decoration: none;
  color: inherit;
  text-align: center;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    margin-bottom: 0;
    text-align: left;
  }

  &:hover {
    text-decoration: underline;
  }
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 0;
  overflow: hidden;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    justify-content: space-between;
  }
`

const Container = styled.div`
  display: block;
  margin-bottom: 30px;
  width: 220px;
  height: 80px;
  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    margin-bottom: 0;
  }

  svg {
    width: 200px;
    height: 80px;
  }
`
const MenuContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    margin-bottom: 0;
    width: auto;
  }
`

const SocialsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    width: auto;
  }
`
const FormSocialContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    width: auto;
  }
`
const FormContainer = styled.div`
  form {
    .form-group {
      margin-bottom: 0px !important;
      max-width: 240px;
    }
    input {
      max-width: 240px;
    }
    input::placeholder {
      color: ${theme.colors.lightgrey} !important;
    }
    @media screen and (max-width: 420px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      .form-group {
        margin-bottom: 0px !important;
        max-width: 100%;
      }
      input {
        max-width: 100%;
      }
    }

    display: flex;
    justify-content: center;
    align-items: center;
    button {
      height: 42px;
      margin-bottom: 28px;
      @media screen and (max-width: 420px) {
        margin-left: 0px;
      }
      margin-left: 5px;
      &:hover {
        &:before {
          background-color: ${theme.colors.secondary} !important;
        }
      }
    }
    label {
      display: none;
    }

    p {
      color: white;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 18px;
      @media screen and (max-width: 420px) {
        width: 100% !important;
      }
      width: 360px !important;
      padding-bottom: 10px;
      .required {
        display: none;
      }
    }
    .footer-form-second-text {
      padding-top: 10px;
    }
  }
  .gform_confirmation_message_56 {
    color: white !important;
  }
`

const CredentialsContainer = styled.div`
  position: relative;
  z-index: 1;
  background: #222522;
  color: #bbb9b9;
`

const ICPNumber = styled.div`
  position: relative;
  z-index: 1;
  color: rgb(89 93 89);
  background: rgb(242, 242, 242);
  padding: 10px;
  text-align: center;
`
