import { createTheme } from "@material-ui/core/styles"
import colors from "./colors"

const defaultTheme = createTheme()
const { breakpoints } = defaultTheme

export default createTheme({
  typography: {
    fontFamily: "HalisGr, sans-serif",
    h1: {
      color: colors.primary,
      fontWeight: 700,
      lineHeight: 1.1,
      fontSize: "30px",
      [breakpoints.up("md")]: {
        fontSize: "40px"
      },
      [breakpoints.up("lg")]: {
        fontSize: "50px"
      }
    },
    h2: {
      color: colors.primary,
      fontWeight: 700,
      fontSize: "26px",
      [breakpoints.up("md")]: {
        fontSize: "32px"
      },
      [breakpoints.up("lg")]: {
        fontSize: "36px"
      }
    },
    h3: {
      color: colors.primary,
      textTransform: "uppercase",
      fontWeight: 800,
      fontSize: "20px",
      [breakpoints.up("md")]: {
        fontSize: "24px"
      }
    },
    h4: {
      color: colors.primary,
      fontWeight: 800,
      fontSize: "16px",
      [breakpoints.up("md")]: {
        fontSize: "18px"
      }
    },
    h5: {
      fontWeight: 700,
      fontSize: "14px",
      color: colors.secondary,
      textTransform: "uppercase"
    },
    h6: {
      color: "#444444",
      fontWeight: 700,
      fontSize: "16px"
    },
    subtitle2: {
      fontFamily: "Montserrat",
      color: colors.primary,
      fontWeight: 800,
      fontSize: "14px"
    },
    body1: {
      color: "#4C656C",
      fontSize: "19px",
      letterSpacing: "0.3px",
      fontWeight: 400
    },
    body2: {
      color: "#4C656C",
      fontSize: "16px",
      letterSpacing: "0.3px",
      fontWeight: 400
    },
    button: {
      fontSize: "14px",
      fontWeight: 700
    }
  },
  palette: {
    primary: {
      main: colors.primary,
      contrastText: "white"
    },
    secondary: {
      main: colors.secondary
    }
  },
  // https://material-ui.com/customization/themes/
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: "1.2rem"
      }
    },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0
      }
    }
  }
})
