const colors = {
  primary: "#2c312c",
  secondary: "#00af3f",
  textgrey: "#727272",
  lightgrey: "#F2F2F2",
  lightmediumgrey: "#dfdfdf",
  mediumgrey: "#969696",
  darkGreen: "#007029"
}

export default colors
