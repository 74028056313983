import React from "react"
import styled, { css } from "styled-components"

import { OpenInNew, Close } from "mdi-material-ui"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"

import YouTube from "./YouTube"
import BackgroundImage from "../backgroundImage"
import { useStore } from "../../store"

export default function Slide(props) {
  const dispatch = useStore()[1]

  const {
    slide: { image, link },
    slide
  } = props

  const isYoutube = link?.url.includes("youtu")

  return (
    <Container {...slide}>
      {!!isYoutube && (
        <LinkToPost href={link.url} target="_blank" rel="noopener referrer">
          <Tooltip title="Watch on YouTube" placement="right">
            <IconButton aria-label="Watch on YouTube">
              <OpenInNew />
            </IconButton>
          </Tooltip>
        </LinkToPost>
      )}
      <ExitButton
        aria-label="Close"
        onClick={() =>
          dispatch({
            type: "CLOSE_LIGHTBOX"
          })
        }
      >
        <IconButton aria-label="Close">
          <Close />
        </IconButton>
      </ExitButton>

      {!!isYoutube && <YouTube video={link.url} />}

      {!isYoutube && (
        <ImageContainer>
          {!!image && !!image.localFile ? (
            <BackgroundImage {...{ image }} position="absolute" />
          ) : (
            <img src={image} alt="" />
          )}
        </ImageContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;

  ${({ link, image }) =>
    !link &&
    !!image &&
    css`
      width: ${image.width}px;
      height: ${image.height}px;
    `}

  max-height: 73vh;
  max-width: 95vw;
`
const ImageContainer = styled.div`
  height: 73vh;
  width: 80vw;
  max-width: 100%;
  max-height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`

const ExitButton = styled.div`
  position: absolute;
  top: -48px;
  right: 0;
  svg {
    fill: white;
  }
`
const LinkToPost = styled.a`
  z-index: 1;
  text-decoration: none;
  color: inherit;
  position: absolute;
  top: -50px;
  left: 0;
  cursor: pointer;
  svg {
    fill: white;
  }
`
