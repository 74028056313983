import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, navigate } from "gatsby"
import CookieConsent from "react-cookie-consent"

// import material ui
import Typography from "@material-ui/core/Typography"

// import app components
import * as theme from "theme"
import { getUrlPath } from "utils/getUrlPath"

const Consent = () => {
  const { cookies } = useCookies()

  const cookie = cookies.find((o) => o.languageCode === process.env.GATSBY_ACTIVE_LANGUAGE)

  return (
    <CookieContainer>
      {cookie && (
        <CookieConsent
          location="bottom"
          buttonText={cookie.buttonText}
          cookieName="realcedar-cookie"
          style={{ background: theme.colors.primary, padding: 15, margin: 0 }}
          buttonStyle={{ background: "#fff", color: theme.colors.primary, fontSize: "13px", margin: 0 }}
          expires={150}
        >
          <Message variant="body2" children={cookie.message} color="inherit" component="div" />

          {cookie.link && (
            <div>
              <Button children={cookie.link.title} onClick={() => navigate(getUrlPath(cookie.link.url))} />
            </div>
          )}
        </CookieConsent>
      )}
    </CookieContainer>
  )
}

const CookieContainer = styled.div`
  .CookieConsent {
    @media (min-width: 640px) {
      flex-wrap: nowrap !important;
      align-items: flex-start !important;
    }

    > div {
      margin: 0 0 15px !important;

      @media (min-width: 640px) {
        display: flex;
        margin: 0 !important;
      }
    }

    button {
      width: 100%;

      @media (min-width: 640px) {
        width: auto;
        width: max-content;
        margin-left: 10px !important;
      }
    }
  }
`

const Message = styled(Typography)`
  margin-bottom: 15px;
  flex: 1;

  @media (min-width: 640px) {
    margin-bottom: 0;
  }
`

const Button = styled.button`
  background: rgb(255, 255, 255);
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
  color: rgb(44, 49, 44);
  cursor: pointer;
  flex: 0 0 auto;
  padding: 5px 10px;
  font-size: 13px;
`

const useCookies = () => {
  const {
    wp: {
      themeSettings: { siteOptions }
    }
  } = useStaticQuery(
    graphql`
      query {
        wp {
          themeSettings {
            siteOptions {
              cookies {
                languageCode
                message
                buttonText
                link {
                  title
                  url
                }
              }
            }
          }
        }
      }
    `
  )

  return siteOptions
}

export default Consent
