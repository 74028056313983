import * as userServices from "./userServices"
import * as imageServices from "./imageServices"
import * as retailerServices from "./retailerServices"

export { userServices, imageServices, retailerServices }

export const request = async (endpoint, token = "") => {
  const response = await fetch(`${process.env.GATSBY_WP}/wp-json/${endpoint}`, {
    method: "get",
    headers: {
      authorization: `Bearer ${token}`
    }
  })

  if ((await response.status) !== 200 && (await response.status) !== 201) {
    throw new Error("Failed!")
  }

  const json = await response.json()

  return json
}
