const submitForm = async (data, apiURL) => {
  if (!data) return

  const endpoint = apiURL + "/submissions"

  // Initialize FormData object
  const formData = new FormData()

  // Helper function to append items correctly to formData
  const appendFormData = (key, value) => {
    if (Array.isArray(value)) {
      // Handle array (list) elements, treat each item as separate form entry
      value.forEach((item) => appendFormData(`${key}[]`, item))
    } else if (typeof value === "string" && value.startsWith("data:")) {
      // Handle base64 encoded strings (files)
      const matches = value.match(/^data:([a-z]+\/[a-z]+);(name=([^;]+);)?base64,(.*)$/)
      if (matches) {
        const contentType = matches[1]
        const filename = matches[3] || "file" // Use provided name or default to "file"
        const base64Data = matches[4]
        const blob = b64toBlob(base64Data, contentType)
        formData.append(key, blob, filename)
      }
    } else {
      // Append non-base64 form data as is
      formData.append(key, value)
    }
  }

  // Iterate through each key in data.formData to process data
  Object.entries(data.formData).forEach(([key, value]) => {
    appendFormData(key, value)
  })

  // Make the POST request with formData
  const result = await fetch(endpoint, {
    method: "POST",
    headers: {
      Accept: "application/json"
      // Don't set Content-Type here, FormData will handle it
    },
    body: formData
  })

  const json = await result.json()

  return json
}

export default submitForm

// Helper function to convert base64 string to Blob
function b64toBlob(b64Data, contentType = "", sliceSize = 512) {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}
