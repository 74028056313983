import produce from "immer"

export const appState = {
  progress: false,
  menu: false,
  menuItemActive: null,
  submenuItemActive: null,
  secondSubmenuItemActive: null,
  search: false,
  activeSection: null,
  newsletter: false,
  scrollLock: false,
  generalSurvey: {
    open: false
  },
  lightBox: {
    slide: 0,
    open: false,
    slides: [],
    options: {
      thumbs: true
    }
  },
  geoInformation: null,
  gallery: {
    categories: [],
    imagesByCategory: [
      {
        category: [],
        images: [],
        cursor: null,
        hasMoreImages: true
      }
    ]
  }
}

export const appReducer = (state, action) => {
  const { payload } = action

  let categoryIndex

  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_PROGRESS":
        draft.progress = payload
        break

      case "SET_SCROLL_LOCK":
        draft.scrollLock = payload
        break

      case "SET_MENU":
        draft.scrollLock = payload
        draft.menu = payload
        break

      case "TOGGLE_MENU":
        draft.scrollLock = !draft.menu
        draft.menu = !draft.menu

        break

      case "SET_MENU_ITEM":
        draft.menuItemActive = payload
        break

      case "SET_SUB_MENU_ITEM":
        draft.submenuItemActive = payload
        break

      case "SET_SECOND_SUB_MENU_ITEM":
        draft.secondSubmenuItemActive = payload
        break

      case "SET_NEWSLETTER":
        draft.newsletter = payload
        break

      case "SET_SEARCH":
        draft.search = payload
        break

      case "SET_ACTIVE_SECTION":
        draft.activeSection = payload
        break

      case "SET_GENERAL_SURVEY":
        draft.generalSurvey = { ...draft.generalSurvey, ...payload }
        break

      case "SET_LIGHTBOX":
        draft.lightBox = { ...draft.lightBox, ...payload }
        break

      case "CLOSE_LIGHTBOX":
        draft.lightBox = {
          slide: 0,
          open: false,
          slides: [],
          options: {
            thumbs: true
          }
        }
        break

      case "SET_GEO_INFORMATION":
        draft.geoInformation = payload
        break

      case "SET_IMAGE_CATEGORIES":
        draft.gallery.categories = payload.tags
        break

      case "SET_IMAGES":
        categoryIndex = draft.gallery.imagesByCategory.findIndex(
          (o) => JSON.stringify(o.category) === JSON.stringify(payload.category)
        )

        if (categoryIndex > -1) {
          // Check if image already exists, if not push to end of array
          const newImages = draft.gallery.imagesByCategory[categoryIndex].images
          payload.resources.map((o) => {
            if (newImages.findIndex((p) => p.public_id === o.public_id) === -1) {
              return newImages.push(o)
            } else {
              return newImages
            }
          })

          draft.gallery.imagesByCategory[categoryIndex] = {
            category: payload.category,
            images: newImages,
            cursor: payload.next_cursor ? payload.next_cursor : null,
            hasMoreImages: payload.next_cursor ? true : false
          }
        } else {
          draft.gallery.imagesByCategory = draft.gallery.imagesByCategory.concat({
            category: payload.category,
            images: payload.resources,
            cursor: payload.next_cursor ? payload.next_cursor : null,
            hasMoreImages: payload.next_cursor ? true : false
          })
        }
        break

      case "SET_IMAGE_DETAILS":
        categoryIndex = draft.gallery.imagesByCategory.findIndex(
          (o) => JSON.stringify(o.category) === JSON.stringify(payload.category)
        )
        const imageIndex = draft.gallery.imagesByCategory[categoryIndex].images.findIndex(
          (o) => o.public_id === payload.image.public_id
        )
        draft.gallery.imagesByCategory[categoryIndex].images[imageIndex] = payload.image
        break

      default:
    }
  })
}
