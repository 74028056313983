import React from "react"
import styled, { css } from "styled-components"

// import material ui
import Typography from "@material-ui/core/Typography"

// import app components
import Link from "components/link"
import MenuItems from "./MenuItems"
import { getUrlPath } from "utils/getUrlPath"
import * as theme from "theme"

const UtilityMenu = props => {
  return (
    <Menu {...props}>
      <MenuItems name={"utility"}>
        {items => {
          return items.map(item => {
            const isExternal = item.url.replace(process.env.GATSBY_WP, "").includes("http")

            return (
              <Typography component="div" key={item.id}>
                {isExternal ? (
                  <ExternalLink href={item.url} target="_blank" rel="noopener">
                    {item.label}
                  </ExternalLink>
                ) : (
                  <StyledLink to={getUrlPath(item.url)}>{item.label}</StyledLink>
                )}
              </Typography>
            )
          })
        }}
      </MenuItems>
    </Menu>
  )
}

export default UtilityMenu

const Menu = styled.nav`
  display: flex;
  justify-content: flex-end;
  height: 100%;
`

const linkStyle = css`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 10px 12px;
  font-size: 12px;
  font-weight: 300;
  text-decoration: none;
  text-transform: uppercase;
  color: ${theme.colors.primary};
`

const StyledLink = styled(Link)`
  ${linkStyle}
`

const ExternalLink = styled.a`
  ${linkStyle}
`
