import React from "react"
import styled, { css } from "styled-components"
import { useScrollYPosition } from "react-use-scroll-position"

import { isIE } from "react-device-detect"

import useWindowSize from "../../utils/useWindowSize"

import ArrowUp from "../../../static/images/icons/arrowup.svg"

export default function ScrollToTop(props) {
  const scrollY = useScrollYPosition()
  const { height } = useWindowSize()
  const scrollPercentage = Math.floor((scrollY / height) * 100)

  return (
    <Container active={scrollPercentage > 20} {...props} isIE={isIE}>
      <ToTop tabIndex="-1" href="#top">
        <ArrowUp className="arrow-up" />
      </ToTop>
    </Container>
  )
}

const Container = styled.div`
  ${({ isIE }) =>
    isIE &&
    css`
      .arrow-up {
        transform: rotate(-90deg);
      }
    `}
  overflow: hidden;
  display: block;
  transition: opacity 0.4s ease-in-out;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 99;
  opacity: 0;
  pointer-events: none;
  ${props =>
    props.active &&
    css`
      pointer-events: all;
      opacity: 1;
    `}
  ${props =>
    props.fixed
      ? css`
          position: fixed;
          bottom: 20px;
          right: 20px;
        `
      : css`
          position: absolute;
          top: -60px;
          right: 20px;
        `}
`
const ToTop = styled.a`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #2c312c;
  border-radius: 50%;
  border: 1px solid #2c312c;
  svg {
    width: 20px;
    height: 20px;
  }
  &:hover {
    background: white;
    svg {
      path {
        fill: #2c312c;
      }
    }
  }
`
