import React from "react"
import Helmet from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

const HrefLang = (props) => {
  const { translations, language, location, defaultLanguage } = props
  const { languages } = useLanguagesQuery()

  const defaultTranslationMatch = translations?.find?.(
    (translation) => translation?.language?.slug === defaultLanguage?.slug
  )

  const defaultLanguageMatch = languages?.find(
    (language) => language?.languageCode?.toLowerCase() === defaultLanguage?.slug
  )

  const fallbackDefaultTranslation = {
    language: {
      slug: defaultLanguage?.slug,
      locale: defaultLanguage?.locale
    },
    uri: location?.href
  }

  const defaultTranslation = defaultTranslationMatch
    ? {
        ...defaultTranslationMatch,
        uri: `${defaultLanguageMatch?.url}${defaultTranslationMatch?.uri?.replace?.(
          `/${defaultTranslationMatch?.language?.slug}`,
          ""
        )}`
      }
    : fallbackDefaultTranslation

  return (
    <Helmet htmlAttributes={{ lang: language?.slug || defaultLanguage?.slug }}>
      {language && <link rel="alternate" href={location.href} hreflang={language?.locale?.replace?.("_", "-")} />}

      {defaultTranslation && <link rel="alternate" href={defaultTranslation.uri} hreflang="x-default" />}

      {translations &&
        translations.map((translation) => {
          const {
            uri,
            language: { locale, slug }
          } = translation

          const hrefLang = locale?.replace?.(/_/g, "-")
          const baseUrl = languages.find((language) => language?.languageCode?.toLowerCase?.() === slug)?.url
          const href = `${baseUrl}${uri?.replace?.(`/${slug}`, ``)}`

          return <link key={slug} rel="alternate" href={href} hrefLang={hrefLang} />
        })}
    </Helmet>
  )
}

const useLanguagesQuery = () => {
  const {
    wp: {
      themeSettings: { siteOptions }
    }
  } = useStaticQuery(
    graphql`
      query {
        wp {
          themeSettings {
            siteOptions {
              languages {
                title
                url
                languageCode
              }
            }
          }
        }
      }
    `
  )
  return siteOptions
}

export default HrefLang
