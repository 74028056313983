import { createGlobalStyle } from "styled-components"
import colors from "./colors"

export default createGlobalStyle`
  html, body {
    max-width: 100vw;
  }
  #section1, #section2, #section3, #section4, #section5 {
    border-top: 130px solid transparent;
    margin-top: -130px;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
}

  a {
    color: ${colors.darkGreen};
  }
  
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @font-face {
    font-family: "HalisGr";
    font-style: normal;
    font-weight: 400;
    src: local("HalisGr"), url('/fonts/HalisGr/HalisGr-100.woff2') format("woff2"), url('/fonts/HalisGr/HalisGr-100.woff') format("woff");
    font-display: swap;
  }

  @font-face {
    font-family: "HalisGr";
    font-style: normal;
    font-weight: 700;
    src: local("HalisGr"), url('/fonts/HalisGr/HalisGr-500.woff2') format("woff2"), url('/fonts/HalisGr/HalisGr-500.woff') format("woff");
    font-display: swap;
  }

  @font-face {
    font-family: "HalisGr";
    font-style: normal;
    font-weight: 500;
    src: local("HalisGr"), url('/fonts/HalisGr/HalisGr-500.woff2') format("woff2"), url('/fonts/HalisGr/HalisGr-500.woff') format("woff");
    font-display: swap;
  }
  @font-face {
    font-family: "HalisGr";
    font-style: normal;
    font-weight: 900;
    src: local("HalisGr"), url('/fonts/HalisGr/HalisGr-500.woff2') format("woff2"), url('/fonts/HalisGr/HalisGr-500.woff') format("woff");
    font-display: swap;
  }

`
