import React, { useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { Dialog, ListItem, Typography } from "@material-ui/core"

import MediaQuery from "components/mediaQuery"
import { menuBreakpoint } from "components/header/Header"
import * as theme from "theme"

const LanguageSwitcher = () => {
  const { languages } = useLanguagesQuery()

  const [open, setOpen] = useState(false)

  const activeLanguage = languages.find(o => o.languageCode === process.env.GATSBY_ACTIVE_LANGUAGE)

  return (
    <>
      <MediaQuery minWidth={menuBreakpoint}>
        <Pipe />
      </MediaQuery>

      <MenuItem children={activeLanguage?.title} onClick={() => setOpen(true)} />

      <StyledDialog open={open} onClose={() => setOpen(false)} fullWidth>
        {languages.map(o => {
          return (
            <StyledListItem key={o.languageCode} button disabled={o.languageCode === activeLanguage?.languageCode}>
              <StyledLink href={o.url} children={<Typography>{o.title}</Typography>} target="_blank" />
            </StyledListItem>
          )
        })}
      </StyledDialog>
    </>
  )
}

const Pipe = styled.div`
  margin: 10px;
  width: 1px;
  height: 18px;
  background: #a3a3a3;
`

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: 300px;
  }
`

const StyledListItem = styled(ListItem)`
  padding: 0;
`

const MenuItem = styled(Typography)`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 10px 12px;
  font-size: 12px;
  font-weight: 300;
  cursor: pointer;
  text-transform: uppercase;
  color: ${theme.colors.primary};
`

const StyledLink = styled.a`
  text-decoration: none;
  color: ${theme.colors.primary};
  display: block;
  width: 100%;
  text-align: center;
  padding: 20px;
`

const useLanguagesQuery = () => {
  const {
    wp: {
      themeSettings: { siteOptions }
    }
  } = useStaticQuery(
    graphql`
      query {
        wp {
          themeSettings {
            siteOptions {
              languages {
                title
                url
                languageCode
              }
            }
          }
        }
      }
    `
  )
  return siteOptions
}

export default LanguageSwitcher
