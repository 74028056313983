import React, { useState, useRef } from "react"
import styled from "styled-components"
import Parser from "html-react-parser"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { Typography, Grid } from "@material-ui/core"

// import app components
import Button from "components/button"
import { GravityForm } from "components/react-gravity-forms"

import { getUrlPath } from "utils/getUrlPath"
import getParameter from "utils/getParameter"

import * as theme from "theme"

const Form = (props) => {
  const { formId, hideTitle, onSuccess, sidebarForm, footerForm } = props

  let { hiddenFields } = props

  let {
    allGfForm,
    allWpRetailer: { nodes: retailers }
  } = useGravityData()

  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)

  const containerRef = useRef(null)

  const form = allGfForm.nodes.find((o) => +o.formId === +formId)

  let formHeader = null

  // Navigate to front page if retailer id can't be validated
  if (typeof window !== `undefined` && getParameter("id")) {
    const retailer = retailers.find((o) => o.databaseId.toString() === getParameter("id"))

    if (!retailer) {
      navigate("/")
    } else {
      formHeader = (
        <Grid container alignItems="center" justify="space-between">
          <Typography variant="h3" children={Parser(retailer.title)} />

          {retailer?.Retailer?.premiumRetailer?.Members?.smallMemberLogo?.sourceUrl && (
            <LogoContainer>
              <img src={retailer.Retailer.premiumRetailer.Members.smallMemberLogo.sourceUrl} alt="Retailer Logo" />
            </LogoContainer>
          )}
        </Grid>
      )
    }
  }

  const handleSuccess = (message) => {
    setSuccess(message)
    scrollToRef(containerRef)
    onSuccess && typeof onSuccess === `function` && onSuccess(message)
  }

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 120)

  const handleError = (err) => {
    console.log({ err })
    setError(err)
  }

  return (
    <Container ref={containerRef}>
      {error && (
        <Message>
          <Typography>Something went wrong. Please try again later.</Typography>
        </Message>
      )}

      {!error && (
        <>
          {formHeader}
          <FormContainer sidebarForm={sidebarForm} footerForm={footerForm} component="div">
            {!success && (
              <GravityForm
                onError={handleError}
                hiddenFields={hiddenFields}
                hideTitle={hideTitle}
                form={form}
                onSuccess={handleSuccess}
                onRedirect={(url) => navigate(getUrlPath(url))}
                reCaptcha={process.env.GATSBY_RECAPTCHA}
                button={<Button children={form?.button?.text || "Submit"} type="submit" />}
              />
            )}

            {success && (
              <Message success>
                <Typography children={Parser(success)} color="inherit" component="div" />
              </Message>
            )}
          </FormContainer>
        </>
      )}
    </Container>
  )
}

export default Form

const Container = styled.div`
  max-width: 800px;
`

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 180px;
  margin-bottom: 10px;
  margin-left: 30px;
  padding: 20px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

  img {
    max-height: 100%;
    max-width: 100%;
  }
`

const FormContainer = styled(({ sidebarForm, footerForm, ...rest }) => <Typography {...rest} />)`
  position: relative;
  padding: ${(props) => (props.footerForm ? "5px 0 40px 0" : props.sidebarForm ? "20px 0" : "40px 0")};

  .gravityForm {
    > .form-group {
      margin-bottom: ${(props) => (props.sidebarForm ? "0px" : "25px")};
    }
  }

  .radio-buttons {
    .field-radio-group {
      display: flex;

      .radio-inline {
        > span {
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          > span {
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            padding: 10px 25px;
            min-width: 125px;

            &:hover {
              background: #2c312c;
              color: white;
            }
          }
        }
      }
    }

    input[type="radio"] {
      display: none;
    }

    input[type="radio"] ~ span {
      background: #f2f2f2;
    }

    input[type="radio"]:checked ~ span {
      background: #00b040 !important;
      color: white !important;
    }

    .field-radio-group {
      display: flex;
      max-width: 500px;

      .radio-inline {
        margin-right: 20px;
      }
    }
  }

  fieldset {
    border: none;
  }

  legend {
    ${{ ...theme.mui.typography.h2 }};
    margin-bottom: 20px;
  }

  .form-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .field {
    width: 100%;
    margin-bottom: 25px;

    &--small {
    }

    &--medium {
      @media (min-width: 640px) {
        width: calc(50% - 10px);
      }
    }

    &--large {
      width: 100%;
    }

    &.fullwidth {
      width: 100%;
    }
  }

  .field.form_left {
    width: calc(50% - 10px);
  }

  .field-radio-group {
    display: flex;
    flex-wrap: wrap;
  }

  label {
    display: block;
    flex-basis: 100%;
    font-size: ${(props) => (props.sidebarForm ? "12px" : "16px")};
  }

  .html {
    padding: 30px 0;

    label {
      display: none;
    }
  }

  .form-control {
    margin-bottom: 10px;
  }

  select,
  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="number"],
  textarea {
    font-family: HalisGr, sans-serif;
    position: relative;
    height: 42px;
    width: 100%;
    margin: 0;
    background: ${theme.colors.lightgrey};
    appearance: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    padding: 5px 10px;
    font-size: 16px;

    &:focus {
      border: 1px solid ${theme.colors.primary};
    }
  }

  select {
    cursor: pointer;
  }

  select[multiple] {
    min-height: 200px;
  }

  .field--select {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      z-index: 10;
      right: 16px;
      bottom: 26px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #383c38;
    }
  }

  .checkbox-inline,
  .radio-inline {
    > span {
      display: inline-block;
      margin-right: 15px;
    }

    > span > span {
      margin-left: 8px;
    }
  }

  .checkbox {
    > label {
      cursor: pointer;

      > span {
        display: inline-block;
        margin-right: 15px;
      }

      > span > span {
        margin-left: 8px;
      }
    }
  }

  textarea {
    height: auto;
    min-height: 150px;
    max-width: 100%;
    width: 100% !important;
  }

  .hide-asterix {
    .control-label {
      display: none;
    }
  }

  .grid {
    .checkboxes {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .checkbox {
      width: 33.3333%;
    }

    img {
      display: block;
      margin: 0 auto 20px;
      height: 200px;
      border: 1px solid #ccc;
    }
  }

  .btn-info {
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da;
  }

  .btn-danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
    flex: none !important;
  }

  .btn {
    display: inline-block;
    padding: 6px 12px !important;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
  }

  .glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: "Glyphicons Halflings";
    font-style: normal;
    font-weight: 400;
    line-height: 1;
  }

  .glyphicon-plus:before {
    content: "+";
  }

  .glyphicon-remove:before {
    content: "x";
    color: #fff;
  }

  .panel.errors {
    display: none;
  }

  .error-detail {
    width: 100%;
    background: #e24141;
    color: #fff;
    padding: 5px 10px;
    margin-top: 10px;

    .text-danger {
      font-size: 14px;
      text-transform: uppercase;
    }
  }
`

const Message = styled.div`
  width: 100%;
  border: 1px solid ${(props) => (props.success ? "#2dad38" : "#e24141")};
  padding: 10px 14px;
  margin-top: 20px;
`

const useGravityData = () => {
  const { allGfForm, allWpRetailer } = useStaticQuery(
    graphql`
      query {
        allGfForm {
          nodes {
            formId
            title
            slug
            apiURL
            labelPlacement
            descriptionPlacement
            formFields {
              id
              label
              labelPlacement
              isRequired
              checkboxLabel
              conditionalLogic
              description
              descriptionPlacement
              type
              choices
              content
              errorMessage
              inputMaskValue
              visibility
              cssClass
              placeholder
              size
              defaultValue
              maxLength
            }
            button {
              text
            }
            confirmations {
              id
              name
              type
              message
            }
          }
        }
        allWpRetailer(filter: { Retailer: { email: { ne: "" }, quoteButton: { eq: true } } }) {
          nodes {
            databaseId
            title
            Retailer {
              premiumRetailer {
                ... on WpMember {
                  Members {
                    excludeFromOrganicSearchResults
                    smallMemberLogo {
                      sourceUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return { allGfForm, allWpRetailer }
}
