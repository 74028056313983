import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Parser from "html-react-parser"

// import material ui
import { Typography } from "@material-ui/core"

// import app components
import MenuItems from "./MenuItems"
import Link from "components/link"
import Edges from "components/edges"
import ProductNav from "./ProductNav"
import ResourcesNav from "./ResourcesNav"
import * as theme from "theme"
import { useStore } from "store"
import { getUrlPath } from "utils/getUrlPath"
import { ChevronDown } from "mdi-material-ui"

const DesktopMenu = (props) => {
  const { name, path, ...rest } = props

  const [
    {
      appState: { activeSection }
    },
    dispatch
  ] = useStore()

  const [isHoverActive, setIsHoverActive] = useState(false)
  const [isHoverChildActive, setIsHoverChildActive] = useState(false)

  useEffect(() => {
    setIsHoverActive(false)
  }, [path])

  return (
    <>
      <Menu {...rest} onMouseEnter={() => setIsHoverActive(true)} onMouseLeave={() => setIsHoverActive(false)}>
        <MenuItems name={name}>
          {(items) => {
            return items
              .filter((item) => item.parentDatabaseId === 0 && !item.cssClasses.includes("hidden"))
              .map((item) => {
                const isActive = activeSection !== "outdoor" && getUrlPath(item.url).includes(`/${activeSection}`)

                return item.cssClasses.includes("products") ? (
                  <ProductNav item={item} key={item.id} component="div" isHoverActive={isHoverActive} path={path} />
                ) : item.cssClasses.includes("resources") ? (
                  <ResourcesNav item={item} key={item.id} component="div" isHoverActive={isHoverActive} />
                ) : (
                  <MenuItem
                    key={item.id}
                    haveChild={item?.childItems?.nodes.length > 0}
                    component="div"
                    isHoverActive={isHoverActive}
                    isActive={getUrlPath(path) === getUrlPath(item.url) ? true : isActive}
                  >
                    <Link to={getUrlPath(item.url)}>{item.label}</Link>
                    {!!item.childItems && item?.childItems?.nodes.length > 0 && (
                      <>
                        <SubMenu>
                          <SubMenuInner>
                            <SubMenuContent>
                              {item.childItems.nodes.map((subItem) => {
                                return (
                                  <SubItem key={subItem.id}>
                                    <SubSubMenu>
                                      <SubSubItemContainer
                                        haveChild={subItem?.childItems?.nodes.length > 0}
                                        component="div"
                                      >
                                        {isHoverActive && (
                                          <SubSubItem
                                            isHoverChildActive={isHoverChildActive === subItem.id}
                                            to={subItem.url}
                                            onClick={() => {
                                              dispatch({
                                                type: "SET_ACTIVE_SECTION",
                                                payload: getUrlPath(item.url).replace(/\//g, "")
                                              })
                                            }}
                                          >
                                            <span>{Parser(subItem.label)}</span>
                                            {subItem?.childItems?.nodes.length > 0 && <ChevronDown />}
                                          </SubSubItem>
                                        )}
                                      </SubSubItemContainer>
                                    </SubSubMenu>
                                    {!!subItem.childItems && subItem?.childItems?.nodes.length > 0 && (
                                      <SubChildMenu
                                        isHoverActive={isHoverActive}
                                        onMouseEnter={() => setIsHoverChildActive(subItem.id)}
                                        onMouseLeave={() => setIsHoverChildActive(false)}
                                      >
                                        <SubChildMenuInner>
                                          <SubMenuContent>
                                            {subItem.childItems.nodes.map((subSubItem) => {
                                              return (
                                                <SubItem key={subSubItem.id}>
                                                  <SubSubMenu>
                                                    <SubSubItemContainer component="div">
                                                      {isHoverActive && (
                                                        <ChildSubItem
                                                          to={getUrlPath(subSubItem.url)}
                                                          onClick={() => {
                                                            dispatch({
                                                              type: "SET_ACTIVE_SECTION",
                                                              payload: getUrlPath(item.url).replace(/\//g, "")
                                                            })
                                                          }}
                                                        >
                                                          <span>{Parser(subSubItem.label)}</span>
                                                        </ChildSubItem>
                                                      )}
                                                    </SubSubItemContainer>
                                                  </SubSubMenu>
                                                </SubItem>
                                              )
                                            })}
                                          </SubMenuContent>
                                        </SubChildMenuInner>
                                      </SubChildMenu>
                                    )}
                                  </SubItem>
                                )
                              })}
                            </SubMenuContent>
                          </SubMenuInner>
                        </SubMenu>
                      </>
                    )}
                  </MenuItem>
                )
              })
          }}
        </MenuItems>
      </Menu>
    </>
  )
}

const Menu = styled.nav`
  display: ${(props) => (props.hide ? "none" : "flex")};
`

const MenuItem = styled(({ isHoverActive, isActive, ...rest }) => <Typography {...rest} />)`
  display: flex;
  align-items: center;
  height: 64px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  color: ${theme.colors.primary};
  cursor: pointer;
  transition: 0.4s ease all;
  background: ${(props) => (props.isActive ? theme.colors.lightgrey : "transparent")};

  > a {
    position: relative;
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0 12px;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: -30px;
      width: calc(100% + 60px);
      height: 25px;
      z-index: 1;
      visibility: hidden;
      pointer-events: none;
      opacity: 0;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 102px;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
    background: rgba(0, 0, 0, 0.6);
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    transition: 0.2s ease all;
    display: ${(props) => (props.haveChild ? "block" : "none")};
  }

  &:hover,
  &:focus {
    ${(props) =>
      props.isHoverActive &&
      `
      background: ${theme.colors.lightgrey};

      &:after {
        transition: 0.2s ease all;
        transition-delay: 0.2s;
        opacity: 1;
        visibility: visible;
      }

      > div {
        transition: 0.2s ease all;
        transition-delay: 0.2s;
        opacity: 1;
        pointer-events: all;
        visibility: visible;
      }

      > a:after {
        opacity: 1;
        pointer-events: all;
        visibility: visible;
      }
    `}
  }
`

const SubMenu = styled(Edges)`
  position: absolute;
  z-index: 1200;
  top: 102px;
  width: fit-content;
  max-width: 300px;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: 0.2s ease all;
`
const SubItem = styled(({ isHoverActive, ...rest }) => <Typography {...rest} />)`
  &:hover,
  &:focus {
    > div {
      opacity: 1;
      pointer-events: all;
      visibility: visible;
    }

    > a:after {
      opacity: 1;
      pointer-events: all;
      visibility: visible;
    }
  }
`
const SubChildMenu = styled(Edges)`
  position: absolute;
  z-index: 1100;
  top: 0;
  width: fit-content;
  max-width: 300px;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  right: -210px;
  padding-left: 20px;
`

const SubMenuInner = styled.div`
  background: ${theme.colors.lightgrey};
  padding: 15px 15px 15px 0px;
  overflow: auto;
`
const SubChildMenuInner = styled.div`
  background: #dedede;
  padding: 15px 15px 15px 0px;
  overflow: auto;
`

const SubMenuContent = styled.div`
  display: flex;
  font-weight: 300;
  flex-direction: column;
`

const SubSubMenu = styled.div``

const SubItemHeadline = styled(Link)`
  display: block;
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
  text-decoration: none;
  color: #000;
  font-family: HalisGr, sans-serif;
  text-transform: capitalize;
  margin-bottom: 16px;
`

const SubSubItemContainer = styled(Typography)`
  padding-left: ${(props) => (props.haveChild ? "0px" : "15px")};
  a {
    padding-left: ${(props) => (props.haveChild ? "15px" : "0px")};
  }
`

const SubSubItem = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 210px;
  background: ${(props) => (props?.isHoverChildActive ? "#dedede" : "unset")};
  &:hover {
    background: #dedede;
  }
  span {
    white-space: pre-line;
    display: inline-block;
    position: relative;
    padding: 7px;
    z-index: 2;
    text-decoration: none;
    color: #000;
    font-size: 14px;
    cursor: pointer;
    transition: ease-in-out all 0.2s;
  }
  svg {
    transition: ease all 0.4s;
    transform: rotate(270deg);
  }
`
const ChildSubItem = styled(Link)`
  display: block;
  width: 210px;
  &:hover {
    background: ${theme.colors.lightgrey};
  }
  span {
    white-space: pre-line;
    display: inline-block;
    position: relative;
    padding: 7px;
    z-index: 2;
    text-decoration: none;
    color: #000;
    font-size: 14px;
    cursor: pointer;
    transition: ease-in-out all 0.2s;
  }
`

export default DesktopMenu
