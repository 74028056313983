import React, { useState } from "react"
import styled from "styled-components"
import Parser from "html-react-parser"

// import material ui
import { Typography } from "@material-ui/core"

// import app components
import Link from "components/link"
import * as theme from "theme"
import GalleryIcon from "../../../static/images/icons/gallery-icon.svg"
import VideosIcon from "../../../static/images/icons/videos-icon.svg"
import BlogIcon from "../../../static/images/icons/blog-icon.svg"
import ToolsIcon from "../../../static/images/icons/tools-icon.svg"
import { useStore } from "store"
import { getUrlPath } from "utils/getUrlPath"

const ResourcesNav = ({ item, isHoverActive }) => {
  const [
    {
      appState: { activeSection }
    },
    dispatch
  ] = useStore()

  const IconRender = (section) => {
    switch (section[0]) {
      case "gallery":
        return (
          <GalleryIcon className="hover:opacity-80 hover:shadow-md hover:shadow-black	hover:ease-in-out duration-500" />
        )
      case "videos":
        return (
          <VideosIcon className="hover:opacity-80 hover:shadow-md hover:shadow-black	hover:ease-in-out duration-500" />
        )

      case "blog":
        return (
          <BlogIcon className="hover:opacity-80 hover:shadow-md hover:shadow-black	hover:ease-in-out duration-500" />
        )

      case "tools":
        return (
          <ToolsIcon className="hover:opacity-80 hover:shadow-md hover:shadow-black	hover:ease-in-out duration-500" />
        )
    }
  }
  const isActive = item?.childItems?.nodes?.some((navitem) => getUrlPath(navitem?.url).includes(`/${activeSection}`))

  return (
    <MenuItem key={item.id} component="div" isHoverActive={isHoverActive} isActive={isActive}>
      <Link to={getUrlPath(item.url)}>{item.label}</Link>

      <SubMenu>
        <div className="relative bg-lightgrey w-full p-[20px] ">
          <div className="w-full flex items-center justify-center gap-[50px] ">
            {item.childItems.nodes.map((subItem, index) => {
              return (
                <Link
                  key={subItem?.id}
                  to={subItem?.url}
                  onClick={() => {
                    dispatch({
                      type: "SET_ACTIVE_SECTION",
                      payload: getUrlPath(item.url).replace(/\//g, "")
                    })
                  }}
                  className="pb-[10px] flex flex-col items-center justify-center gap-[10px]"
                >
                  <Typography className="text-[12px] font-medium uppercase text-textColor">
                    {Parser(subItem?.label)}
                  </Typography>
                  <div>{IconRender(subItem?.cssClasses)}</div>
                </Link>
              )
            })}
          </div>
        </div>
      </SubMenu>
    </MenuItem>
  )
}

const MenuItem = styled(({ isHoverActive, isActive, ...rest }) => <Typography {...rest} />)`
  display: flex;
  align-items: center;
  height: 64px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  color: ${theme.colors.primary};
  transition: 0.4s ease all;
  background: ${(props) => (props.isActive ? theme.colors.lightgrey : "transparent")};

  > a {
    position: relative;
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0 12px;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: -30px;
      width: calc(100% + 60px);
      height: 25px;
      z-index: 1;
      visibility: hidden;
      pointer-events: none;
      opacity: 0;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 102px;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
    background: rgba(0, 0, 0, 0.6);
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    transition: 0.2s ease all;
    display: block;
  }
  &:hover,
  &:focus {
    ${(props) =>
      props.isHoverActive &&
      `
      background: ${theme.colors.lightgrey};

      &:after {
        transition: 0.2s ease all;
        transition-delay: 0.2s;
        opacity: 1;
        visibility: visible;
      }

      > div {
        transition: 0.2s ease all;
        transition-delay: 0.2s;
        opacity: 1;
        pointer-events: all;
        visibility: visible;
      }

      > a:after {
        opacity: 1;
        pointer-events: all;
        visibility: visible;
      }
    `}
  }
`

const SubMenu = styled.div`
  position: absolute;
  z-index: 1200;
  top: 102px;
  width: fit-content;
  max-width: 1110px;
  display: flex;
  justify-content: flex-start;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: 0.2s ease all;
  /* left: calc(50% - 300px);
  transform: translateX(-50%); */
  transform: translateX(-285px);
`

export default ResourcesNav
