import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import FbIcon from "../../../static/images/icons/fb.svg"
import InstagramIcon from "../../../static/images/icons/instagram.svg"
import PinterestIcon from "../../../static/images/icons/pinterest.svg"
import YouTubeIcon from "../../../static/images/icons/youtube.svg"
import LinkedinIcon from "../../../static/images/icons/linkedin.svg"

import * as theme from "theme"

const Socials = () => {
  const { facebookUrl, instagramUrl, pinterestUrl, youtubeUrl, linkedinUrl } = useSocialsQuery()

  return (
    <Container>
      {!!facebookUrl && (
        <a href={facebookUrl} target="_blank" rel="noopener noreferrer" aria-label={"Facebook"}>
          <FbIcon />
        </a>
      )}
      {!!instagramUrl && (
        <a href={instagramUrl} target="_blank" rel="noopener noreferrer" aria-label={"Instagram"}>
          <InstagramIcon />
        </a>
      )}
      {!!pinterestUrl && (
        <a href={pinterestUrl} target="_blank" rel="noopener noreferrer" aria-label={"Pinteret"}>
          <PinterestIcon />
        </a>
      )}
      {!!youtubeUrl && (
        <a href={youtubeUrl} target="_blank" rel="noopener noreferrer" aria-label={"YouTube"}>
          <YouTubeIcon />
        </a>
      )}
      {!!linkedinUrl && (
        <a href={linkedinUrl} target="_blank" rel="noopener noreferrer" aria-label={"linkedin"}>
          <LinkedinIcon />
        </a>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;

  svg {
    padding: 8px;
    width: 40px;
    height: 40px;
    background: #ffffff;
    border-radius: 50%;
    path {
      fill: #000;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  a {
    display: block;
    margin-left: 10px;
    margin-right: 10px;

    @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
      margin-left: 10px;
    }
  }
`

const useSocialsQuery = () => {
  const {
    wp: {
      themeSettings: { siteOptions }
    }
  } = useStaticQuery(
    graphql`
      query {
        wp {
          themeSettings {
            siteOptions {
              facebookUrl
              instagramUrl
              pinterestUrl
              youtubeUrl
              linkedinUrl
            }
          }
        }
      }
    `
  )
  return siteOptions
}

export default Socials
