import React from "react"
import styled from "styled-components"
import { MuiThemeProvider } from "@material-ui/core/styles"
import "minireset.css"
import Helmet from "react-helmet"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

// import app components
import Header from "components/header"
import Footer from "components/footer"
import Seo from "components/seo"
import HrefLang from "components/hrefLang"
import Newsletter from "components/newsletter"
import LightBox from "components/lightBox"
import GeneralSurvey from "components/generalSurvey"

import HalisGr100 from "../../../static/fonts/HalisGr/HalisGr-100.woff2"
import HalisGr500 from "../../../static/fonts/HalisGr/HalisGr-500.woff2"

// import others
import { mui } from "theme"
import { Store } from "store"

const queryClient = new QueryClient()

const Layout = (props) => {
  const { path, location, pageContext, data } = props

  return (
    <QueryClientProvider client={queryClient}>
      <Store>
        <MuiThemeProvider theme={mui}>
          <div id="top">
            <Helmet>
              <link rel="preload" as="font" href={HalisGr100} type="font/woff2" crossOrigin="anonymous" />
              <link rel="preload" as="font" href={HalisGr500} type="font/woff2" crossOrigin="anonymous" />
              {process.env.GATSBY_ACTIVE_LANGUAGE === "EN" && (
                <script>
                  {`!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','t2_kg01xkcy');rdt('track', 'PageVisit');`}
                </script>
              )}
              <script>
                {`!function (w, d, t) {
                w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
                ttq.load('CNLLRHBC77UCDEI9U8P0');
                ttq.page();
              }(window, document, 'ttq');`}
              </script>
            </Helmet>

            <Seo {...pageContext} {...data} />
            <HrefLang location={location} {...pageContext} />

            <Header path={path} {...pageContext} data={data} />
            <Container>{props.children}</Container>
            <Footer />

            <Newsletter />
            <LightBox />
            {process.env.GATSBY_ACTIVE_LANGUAGE === "EN" && <GeneralSurvey location={location} />}
          </div>
        </MuiThemeProvider>
      </Store>
    </QueryClientProvider>
  )
}

const Container = styled.div`
  padding-top: 102px;
  position: relative;
  min-height: calc(100vh - 400px);
  max-width: 100vw;
  background: #fdfafa;
  .gatsby-image-wrapper {
    overflow: hidden;
    max-width: 100vw;
  }
`

export default Layout
