export async function handleTracking(event, uri) {
  if (process.env.NODE_ENV === "development") return
  const conversionsData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      eventName: event,
      eventSource: "https://realcedar.com" + uri
    })
  }
  return await fetch("/api/conversions", conversionsData)
}
