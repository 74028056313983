import React from "react"
import styled from "styled-components"

// import material ui
import Typography from "@material-ui/core/Typography"

// import app components
import Link from "components/link"
import MenuItems from "./MenuItems"
import * as theme from "theme"
import { getUrlPath } from "../../utils/getUrlPath"

const FooterMenu = props => {
  return (
    <Menu {...props}>
      <MenuItems name={"footer"}>
        {items => {
          return items.map(item => (
            <Typography component="div" key={item.id}>
              <StyledLink to={getUrlPath(item.url)}>{item.label}</StyledLink>
            </Typography>
          ))
        }}
      </MenuItems>
    </Menu>
  )
}

export default FooterMenu

const Menu = styled.nav``

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 4px 12px 0;
  text-decoration: none;
  color: #fff;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    justify-content: flex-start;
  }
`
