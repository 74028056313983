import React from "react"
import { GlobalStyles } from "./src/theme"
import { handleTracking } from "./src/utils/handleTracking"
import "unfetch/polyfill"
// Required to enable image uploads with react gravity forms
import "base64-js"
import "./src/theme/index.css"

export const onInitialClientRender = () => {
  // Scroll to location.hash on page load
  if (typeof document !== `undefined` && typeof window !== `undefined`) {
    const hashElement = document.getElementById(window.location.hash.replace("#", ""))
    if (!!hashElement) {
      hashElement.scrollIntoView()
      window.scrollBy(0, -120)
    }
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <GlobalStyles />
      {element}
    </>
  )
}

export const onRouteUpdate = ({ location }) => {
  process.env.GATSBY_ACTIVE_LANGUAGE === "EN" && handleTracking("PageView", location)
}
