import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Parser from "html-react-parser"

// import material ui
import { Typography } from "@material-ui/core"

// import app components
import MenuItems from "./MenuItems"
import Link from "components/link"
import Edges from "components/edges"
import Spacer from "components/spacer"
import BackgroundImage from "components/backgroundImage"
import RoundedButton from "./RoundedButton"

import NavIcon from "../../assets/svg/navIcon.svg"
import AccountIcon from "../../assets/svg/accountIcon.svg"
import * as theme from "theme"
import { useStore } from "store"
import { getUrlPath } from "utils/getUrlPath"

const DesktopMenu = (props) => {
  const { name, path, ...rest } = props

  const [
    {
      appState: { activeSection }
    },
    dispatch
  ] = useStore()

  const [isHoverActive, setIsHoverActive] = useState(false)

  useEffect(() => {
    setIsHoverActive(false)
  }, [path])

  return (
    <>
      <Menu {...rest} onMouseEnter={() => setIsHoverActive(true)} onMouseLeave={() => setIsHoverActive(false)}>
        <MenuItems name={name}>
          {(items) => {
            return items
              .filter((item) => item.parentDatabaseId === 0 && !item.cssClasses.includes("hidden"))
              .map((item) => {
                const childItemsCount =
                  item?.childItems?.nodes &&
                  item.childItems.nodes.filter((o) => !o.cssClasses.includes("section-title")).length

                const isActive = getUrlPath(item.url).includes(`/${activeSection}`)

                const hasImage = !!item.menuBanner.image
                const columnWidth = 100 / (hasImage && !item.cssClasses.includes("membership") ? 5 : 4)

                return (
                  <MenuItem key={item.id} component="div" isHoverActive={isHoverActive} isActive={isActive}>
                    <Link to={getUrlPath(item.url)}>{item.label}</Link>

                    {!!item.childItems && (
                      <>
                        <SubMenu>
                          <SubMenuInner
                            style={{
                              width: `${childItemsCount * columnWidth > 100 ? 100 : childItemsCount * columnWidth}%`
                            }}
                          >
                            <SubMenuContent>
                              {item.childItems.nodes
                                .filter((o) => !o.cssClasses.includes("section-title"))
                                .map((subItem) => {
                                  return (
                                    <SubItem
                                      key={subItem.id}
                                      style={{
                                        width: `${100 / childItemsCount}%`
                                      }}
                                    >
                                      <SubItemHeadline variant="caption" children={Parser(subItem.label)} />

                                      {subItem.childItems && (
                                        <SubSubMenu>
                                          {subItem.childItems.nodes.map((subSubItem) => {
                                            return (
                                              <div key={subSubItem.id}>
                                                {subSubItem.cssClasses.includes("nav-title") ? (
                                                  <Spacer pt={20}>
                                                    <SubItemHeadline
                                                      variant="caption"
                                                      children={Parser(subSubItem.label)}
                                                    />
                                                  </Spacer>
                                                ) : (
                                                  <SubSubItemContainer component="div">
                                                    {isHoverActive && (
                                                      <SubSubItem
                                                        to={getUrlPath(subSubItem.url)}
                                                        onClick={() => {
                                                          dispatch({
                                                            type: "SET_ACTIVE_SECTION",
                                                            payload: getUrlPath(item.url).replace(/\//g, "")
                                                          })
                                                        }}
                                                      >
                                                        <span>{Parser(subSubItem.label)}</span>
                                                      </SubSubItem>
                                                    )}
                                                  </SubSubItemContainer>
                                                )}
                                              </div>
                                            )
                                          })}
                                        </SubSubMenu>
                                      )}
                                    </SubItem>
                                  )
                                })}
                            </SubMenuContent>
                          </SubMenuInner>

                          {hasImage && (
                            <SubItem
                              style={{
                                padding: 0,
                                width: `${100 / childItemsCount}%`,
                                maxWidth: "250px"
                              }}
                            >
                              <Banner>
                                <BannerHeadline>{item.menuBanner.title}</BannerHeadline>

                                <BannerImage>
                                  <RoundedButton
                                    color="green"
                                    icon={item.cssClasses.includes("membership") ? <AccountIcon /> : <NavIcon />}
                                    title={item.cssClasses.includes("membership") ? "Become a member" : "Where to buy"}
                                    to={
                                      item.cssClasses.includes("membership")
                                        ? "/membership/become-a-wrcla-member"
                                        : "/find-a-retailer"
                                    }
                                  />
                                  <BackgroundImage image={item.menuBanner.image} />
                                </BannerImage>
                              </Banner>
                            </SubItem>
                          )}
                        </SubMenu>
                      </>
                    )}
                  </MenuItem>
                )
              })
          }}
        </MenuItems>
      </Menu>
    </>
  )
}

const Banner = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  button {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const BannerHeadline = styled(Typography)`
  position: relative;
  padding: 30px 24px 50px;
  background: #2c2c2c;
  color: #fff;
  font-size: 28px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
`

const BannerImage = styled.div`
  position: relative;
  flex: 1;
`

const Menu = styled.nav`
  display: flex;
`

const MenuItem = styled(({ isHoverActive, isActive, ...rest }) => <Typography {...rest} />)`
  display: flex;
  align-items: center;
  height: 64px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  color: ${theme.colors.primary};
  cursor: pointer;
  transition: 0.4s ease all;
  background: ${(props) => (props.isActive ? theme.colors.lightgrey : "transparent")};

  > a {
    position: relative;
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0 12px;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: -30px;
      width: calc(100% + 60px);
      height: 25px;
      z-index: 1;
      visibility: hidden;
      pointer-events: none;
      opacity: 0;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 102px;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
    background: rgba(0, 0, 0, 0.6);
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    transition: 0.2s ease all;
  }

  &:hover,
  &:focus {
    ${(props) =>
      props.isHoverActive &&
      `
      background: ${theme.colors.lightgrey};

      &:after {
        transition: 0.2s ease all;
        transition-delay: 0.2s;
        opacity: 1;
        visibility: visible;
      }

      > div {
        transition: 0.2s ease all;
        transition-delay: 0.2s;
        opacity: 1;
        pointer-events: all;
        visibility: visible;
        min-height: 400px;
      }

      > a:after {
        opacity: 1;
        pointer-events: all;
        visibility: visible;
      }
    `}
  }
`

const SubMenu = styled(Edges)`
  position: absolute;
  z-index: 1200;
  top: 102px;
  left: 50%;
  transform: translateX(-50%);
  width: 94%;
  max-width: 1110px;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: 0.2s ease all;
`

const SubMenuInner = styled.div`
  background: ${theme.colors.lightgrey};
  padding: 20px;
  max-height: calc(100vh - 102px);
  overflow: auto;
  border-bottom: 1px solid #e8e8e8;
`

const SubMenuContent = styled.div`
  display: flex;
`

const SubItem = styled.div`
  padding: 10px;
`

const SubItemHeadline = styled(Typography)`
  display: block;
  font-weight: bold;
  text-decoration: none;
  color: ${theme.colors.primary};
  text-transform: uppercase;
  margin-bottom: 16px;
`

const SubSubMenu = styled.div``

const SubSubItemContainer = styled(Typography)`
  && {
    line-height: 18px;
    margin-bottom: 10px;
  }
`

const SubSubItem = styled(Link)`
  display: block;

  span {
    display: inline-block;
    position: relative;
    z-index: 2;
    text-decoration: none;
    color: ${theme.colors.primary};
    font-size: 15px;
    cursor: pointer;
    transition: ease-in-out all 0.2s;

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: -4px;
      left: -8px;
      height: calc(100% + 8px);
      width: 0;
      background: ${theme.colors.primary};
      transition: ease-in-out all 0.2s;
    }

    &:hover {
      color: ${theme.colors.lightgrey};
    }

    &:hover:before {
      width: calc(100% + 16px);
    }
  }
`

export default DesktopMenu
