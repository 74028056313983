import React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import Typography from "@material-ui/core/Typography"

import Form from "components/form"
import { useStore } from "store"

const Newsletter = () => {
  const [
    {
      appState: { newsletter }
    },
    dispatch
  ] = useStore()

  return (
    <Dialog
      open={newsletter}
      onClose={() => dispatch({ type: "SET_NEWSLETTER", payload: false })}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <Typography children="Sign up for our Newsletter" variant="h2" />
        <Form hideTitle formId={40} style={{ padding: 0 }} />
      </DialogContent>
    </Dialog>
  )
}

export default Newsletter
