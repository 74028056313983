import React, { useState } from "react"
import styled, { css } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { useForm, Controller } from "react-hook-form"
import {
  TextField,
  Typography,
  FormControlLabel,
  FormControl,
  FormGroup,
  Radio,
  RadioGroup,
  FormHelperText,
  Checkbox,
  MenuItem,
  Select,
  InputLabel
} from "@material-ui/core"
import Textarea from "components/textarea"

import Button from "components/button"

const GeneralSurveyForm = (props) => {
  const { onSuccess, startingTime } = props

  const { title, formFields, apiURL, button } = useGravityData()

  const { register, handleSubmit, control, errors, watch } = useForm()
  const watchAllFields = watch()

  const [submitFormData, { loading }] = useLazyFetch(`${apiURL}/submissions`, {
    method: "POST",
    "Content-Type": "application/x-www-form-urlencoded",
    onCompleted: (res) => {
      onSuccess()
    },
    onError: (err) => {
      console.log({ err })
    }
  })

  const onSubmit = (data, e) => {
    if (!data) return

    const submittedTime = new Date().getTime()
    const differenceMilliseconds = !!startingTime && submittedTime - startingTime
    const mins = !!differenceMilliseconds && Math.floor(differenceMilliseconds / 60000)
    const seconds = !!differenceMilliseconds && ((differenceMilliseconds % 60000) / 1000).toFixed(0)
    const differenceFormatted = `${mins}:${seconds}`

    const combinedData = { ...data, input_24: differenceFormatted, input_25: differenceMilliseconds }

    const formData = new FormData()
    Object.keys(combinedData).map((key) => formData.append(key, combinedData[key]))

    submitFormData({ body: formData })
  }

  const getField = (fieldId) => {
    const field = !!formFields && formFields.find((item) => item.id === fieldId)
    return field
  }

  const validateCheckBox = (fieldId) => {
    let valid = false
    const keys = Object.keys(watchAllFields)
    const fieldKeys = keys.map((key) => key.startsWith(`input_${fieldId}_`) && key).filter((el) => !!el && el)

    fieldKeys &&
      fieldKeys.forEach((fieldKey) => {
        if (!!watchAllFields[fieldKey]) {
          valid = true
        }
      })

    return valid
  }

  const renderField = (fieldId) => {
    const field = getField(fieldId)
    const choices = field?.choices && JSON.parse(field.choices)

    switch (field.type) {
      case "text":
        return (
          <TextField
            className={`gform-field gform-field--${field.size} ${field.cssClass}`}
            inputRef={register}
            name={`input_${field.id}`}
            label={field?.label}
            // required={field?.isRequired} //test
            required={true}
          />
        )
      case "textarea":
        return (
          <TextField
            className={`gform-field gform-field--${field.size} ${field.cssClass}`}
            inputRef={register}
            name={`input_${field.id}`}
            label={field?.label}
            // required={field?.isRequired}
            required={true}
          />
        )
      case "radio":
        const radioChoices = field?.choices && JSON.parse(field.choices)
        return (
          <>
            {field?.label && <Typography gutterBottom>{field.label}</Typography>}
            <FormControl
              error={!!errors?.[`input_${field.id}`]}
              fullWidth
              className={`gform-field gform-field--large ${field.cssClass}`}
            >
              <RadioButtons>
                <Controller
                  as={RadioGroup}
                  name={`input_${field.id}`}
                  control={control}
                  row
                  defaultValue=""
                  // rules={{ required: field?.isRequired }} //
                  rules={{ required: true }}
                >
                  {radioChoices &&
                    radioChoices.map((choice, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={choice?.value}
                          control={<Radio color="primary" />}
                          label={choice?.text}
                          labelPlacement="top"
                        />
                      )
                    })}
                </Controller>
              </RadioButtons>
              {!!errors?.[`input_${field.id}`] && <FormHelperText>Please select an option.</FormHelperText>}
            </FormControl>
          </>
        )

      case "select":
        return (
          <>
            {field?.label && <Typography>{field.label}</Typography>}
            <FormControl
              error={!!errors?.[`input_${field.id}`]}
              fullWidth
              className={`gform-field gform-field--${field.size} ${field.cssClass}`}
            >
              {field?.label && (
                <InputLabel id={`gform-select-${field.id}`}>{field?.placeholder || `Select one`}</InputLabel>
              )}
              <Controller
                as={Select}
                control={control}
                defaultValue=""
                labelId={`gform-select-${field.id}`}
                id={`gform-select-${field.id}`}
                name={`input_${field.id}`}
                placeholder="Select one"
                // rules={{ required: field?.isRequired }}
                rules={{ required: true }}
              >
                {choices &&
                  choices.map((choice, index) => {
                    if (choice?.text || choice?.value !== "") {
                      return (
                        <MenuItem key={index} value={choice.value}>
                          {choice.text}
                        </MenuItem>
                      )
                    }
                    return null
                  })}
              </Controller>
              {!!errors?.[`input_${field.id}`] && <FormHelperText>Please select an option.</FormHelperText>}
            </FormControl>
          </>
        )

      case "checkbox":
        const checkboxChoices = field?.choices && JSON.parse(field.choices)

        return (
          <>
            {field?.label && <Typography gutterBottom>{field.label}</Typography>}

            <FormGroup className={`${field.cssClass} gform-field gform-field--large`}>
              {checkboxChoices &&
                checkboxChoices.map((choice, index) => {
                  if (choice?.text || choice?.value !== "") {
                    return (
                      <Controller
                        key={index}
                        name={`input_${field.id}_${index + 1}`}
                        control={control}
                        defaultValue=""
                        rules={{
                          validate: () => validateCheckBox(field.id) || "Please select an option"
                        }}
                        render={(props) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => props.onChange(!!e.target.checked ? choice.value : "")}
                                checked={!!props.value}
                              />
                            }
                            name={`input_${field.id}_${index + 1}`}
                            label={choice.text}
                          />
                        )}
                      />
                    )
                  }
                  return null
                })}
              {!!errors?.[`input_${field.id}_0`] && <FormHelperText error>Please select an option.</FormHelperText>}
            </FormGroup>
          </>
        )

      case "html":
        return (
          <div className={`gform-field gform-field--large ${field.cssClass}`}>
            {field?.label && <Typography>{field.label}</Typography>}
            {field?.content && <Textarea content={field.content} />}
          </div>
        )
      default:
        return null
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {title && (
        <Typography variant="h2" gutterBottom>
          {title}
        </Typography>
      )}
      <Fields>
        {renderField(20)}
        {renderField(4)}
        {renderField(21)}

        {watchAllFields?.input_21 === "Other" && renderField(22)}

        {!!watchAllFields?.input_21 &&
          watchAllFields?.input_21 !== "Just exploring ideas or design concepts, I do not have a project in mind" &&
          renderField(5)}

        {watchAllFields?.input_5_4 === "Other" && renderField(6)}

        {!!watchAllFields?.input_21 &&
          watchAllFields?.input_21 !== "Just exploring ideas or design concepts, I do not have a project in mind" &&
          renderField(7)}

        {renderField(8)}
        {watchAllFields?.input_8 === "Other" && renderField(23)}

        {renderField(10)}
        {watchAllFields?.input_10 === "No" && renderField(11)}

        {watchAllFields?.input_8 === "Homeowner" && renderField(12)}

        <Typography>
          Thank you for your feedback!  Your input is extremely valuable to helping us improve the resources we provide
          to Realcedar.com visitors like you.
        </Typography>

        {renderField(13)}
        {watchAllFields?.input_13 === "Yes" && renderField(18)}
        {watchAllFields?.input_13 === "Yes" && renderField(14)}
        {watchAllFields?.input_13 === "Yes" && renderField(15)}
        {watchAllFields?.input_13 === "Yes" && renderField(16)}
        {watchAllFields?.input_13 === "No" && renderField(17)}
      </Fields>

      <Button
        disabled={loading}
        type="submit"
        css={css`
          margin-top: 20px;
        `}
      >
        {button?.text || `Submit`}
      </Button>
    </Form>
  )
}

const Form = styled.form`
  padding: 30px 0;
  display: block;
`

const Fields = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > .gform-field {
    margin-bottom: 20px;
    width: calc(50% - 16px);
    &--large {
      width: 100%;
    }
  }
`

const RadioButtons = styled.div`
  display: flex;
  align-items: center;
  .MuiFormControlLabel-root {
    margin: 0;
  }
`

const useGravityData = () => {
  const { gfForm: form } = useStaticQuery(graphql`
    {
      gfForm(formId: { eq: 46 }) {
        title
        formId
        title
        slug
        apiURL
        labelPlacement
        descriptionPlacement
        formFields {
          id
          label
          labelPlacement
          isRequired
          checkboxLabel
          conditionalLogic
          description
          descriptionPlacement
          type
          choices
          content
          errorMessage
          inputMaskValue
          visibility
          cssClass
          placeholder
          size
          defaultValue
          maxLength
        }
        button {
          text
        }
        confirmations {
          id
          name
          type
          message
        }
      }
    }
  `)
  return form
}

const useLazyFetch = (endpoint, options = {}) => {
  const { onError = null, onCompleted = null, ...restOptions } = options

  const [state, setState] = useState({
    error: null,
    data: null,
    loading: false,
    called: false
  })

  const callback = async (args) => {
    setState({
      ...state,
      called: true,
      loading: true,
      error: null
    })

    await fetch(endpoint, {
      method: "get",
      ...restOptions,
      ...args
    })
      .then(async (response) => {
        const json = await response.json()

        setState({
          ...state,
          loading: false,
          data: response.status === 200 ? json : null,
          called: true,
          error: response.status !== 200 ? json : null
        })

        if (response.status === 200) {
          onCompleted && typeof onCompleted === `function` && onCompleted(json)
        } else {
          onError && typeof onError === `function` && onError(json)
        }
      })
      .catch((error) => {
        setState({ ...state, error, loading: false, called: true })
        onError && typeof onError === `function` && onError(error)
      })
  }

  return [callback, state]
}

export default GeneralSurveyForm
